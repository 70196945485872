<template>
  <v-dialog
    v-model="isOpen"
    width="700"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    content-class="rounded-lg"
  >
    <v-card class="h-full d-flex flex-column" style="max-height: 90dvh">
      <v-card-title class="pb-0 pt-3 px-6">
        <span class="text-16">
          {{ coupon?.id ? "Editar cupom" : "Adicionar cupom" }}
        </span>
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-stepper
          v-model="step"
          class="elevation-0"
          :non-linear="!!coupon?.id"
        >
          <v-stepper-header class="elevation-0 mb-2" style="height: 50px">
            <v-stepper-step :complete="step > 1" :step="1" class="py-0">
              Detalhes
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="step > 2" :step="2" class="py-0">
              Regras
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="step > 3" :step="3" class="py-0">
              Códigos
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1" class="pt-0">
              <coupon-details-form
                v-model="coupon"
                :valid.sync="validDetails"
              />
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-0">
              <coupon-rules-form
                v-model="coupon"
                :party="party"
                :valid.sync="validRules"
                :ticketGroups="ticketGroups"
              />
            </v-stepper-content>
            <v-stepper-content step="3" class="pt-0">
              <coupon-codes-form v-model="coupon" :valid.sync="validCodes" />

              <v-alert v-if="!!error" outlined text color="error" class="mt-2">
                {{ error }}
              </v-alert>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <v-card-actions class="d-flex align-end gap-1">
        <template v-if="step === 1">
          <v-switch
            class="mt-0"
            v-model="coupon.active"
            label="Ativo"
            :messages="[coupon.active ? 'Cupom ativo' : 'Cupom inativo']"
          />
          <v-spacer />
          <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
          <v-btn
            color="primary"
            :disabled="!validDetails || loading"
            @click="step++"
          >
            Continuar
          </v-btn>
        </template>
        <template v-if="step === 2">
          <v-btn text :disabled="loading" @click="step--"> Voltar </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="!validRules || loading"
            @click="step++"
          >
            Continuar
          </v-btn>
        </template>
        <template v-if="step === 3">
          <v-btn text :disabled="loading" @click="step--"> Voltar </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="!validCodes || loading"
            @click="save"
          >
            Salvar
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";
import TICKET from "@/services/admin/ticket";
import COUPON from "@/services/admin/party/coupon";

import CouponDetailsForm from "./components/CouponDetailsForm.vue";
import CouponRulesForm from "./components/CouponRulesForm.vue";
import CouponCodesForm from "./components/CouponCodesForm.vue";

const defaultCoupon = () => ({
  id: null,
  name: "",
  description: "",
  validUntil: null,
  maxTickets: null,
  showOnline: false,
  active: true,
});

export default {
  components: { CouponDetailsForm, CouponRulesForm, CouponCodesForm },
  data: () => ({
    isOpen: false,
    loading: false,
    validDetails: false,
    validRules: false,
    validCodes: false,
    error: false,
    step: 1,
    coupon: defaultCoupon(),
    ticketGroups: [],
  }),

  methods: {
    open(data = {}) {
      this.isOpen = true;
      this.seller = Object.assign(defaultCoupon(), data);
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.step = 1;
      this.seller = defaultCoupon();
    },
    formatName(name) {
      return name
        .split(" ")
        .map((n) => (n.length > 3 ? n.charAt(0).toUpperCase() + n.slice(1) : n))
        .join(" ");
    },
    async getTicketGroups() {
      this.loading = true;
      try {
        const response = await TICKET.getTickets(
          this.party.organizationId,
          this.party.id
        );
        this.ticketGroups = response.ticketGroups;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    async save() {
      this.loading = true;
      try {
        const response = await COUPON.create(
          this.party.organizationId,
          this.party.id,
          this.coupon
        );
        this.$emit("refresh", response);
        this.close();
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {},

  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$root.$on("coupon-modal", this.open);
    this.getTicketGroups();
  },
  props: {
    party: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {},
};
</script>

<style></style>
