const { VUE_APP_PLATFORM_NAME } = process.env;

const ExcelJS = require("exceljs");
const moment = require("moment");

const downloadFile = require("./utils/downloadFile");
const header = require("./modules/header");
const formatName = require("./utils/formatName");
const PAYMENT = require("../payment.js").default;

const store = require("../../store").default;

module.exports = async ({ party, tableMap, groups, }) => {
  const opMode = store?.getters["auth/config"]?.opMode || false;

  const workbook = new ExcelJS.Workbook();
  workbook.creator = VUE_APP_PLATFORM_NAME;
  workbook.lastModifiedBy = VUE_APP_PLATFORM_NAME;

  workbook.title = `Assentos - ${formatName(party.name)}`;
  workbook.created = new Date();

  const sheetResume = workbook.addWorksheet("Resumo", {
    properties: { tabColor: { argb: "242939" } },
    views: [{ showGridLines: false }]
  });

  const columns = [
    { key: "name", width: 50, header: "Assento", style: { font: { bold: true } } },
    { key: "status", width: 15, header: "Status", style: { font: { bold: true } } },
    { key: "date", width: 20, header: "Vendido em", style: { font: { bold: true } } },
    { key: "value", width: 20, header: "Valor", style: { font: { bold: true } } },
    { key: "paymentMethod", width: 20, header: "Meio de pagto", style: { font: { bold: true } } },
    { key: "paymentType", width: 20, header: "Forma de pagto", style: { font: { bold: true } } },
    { key: "ownerName", width: 29, header: "Nome do titular", style: { font: { bold: true } } },
    { key: "ownerPhone", width: 25, header: "Telefone do titular", style: { font: { bold: true } } },
  ];

  sheetResume.columns = columns.flat();
  sheetResume.columns.forEach((column, index) => {
    if (!index) column.alignment = { vertical: "middle", horizontal: "left" };
    else column.alignment = { vertical: "middle", horizontal: "center" };
  });
  applyStyle(sheetResume, {
    font: { name: "Courier New" }
  });


  await header(workbook, sheetResume, { title: "Assentos vendidos", party });

  // Dados
  for (const group of tableMap.Groups) {
    const groupData = groups[group.id];
    const sortedTables = group.Tables.sort((a, b) => a.name.localeCompare(b.name));
    for (const table of sortedTables) {
      const tickets = groupData?.tickets.filter((ticket) => ticket.Table.id === table.id) || [];
      const ticket = tickets?.[0];
      const status = !!ticket ? "Vendido" : "Disponível";

      const r = sheetResume.addRow({
        name: `${group.name} • ${table.name}`,
        status,
        date: ticket ? moment(ticket?.Payment?.createdAt).format("DD/MM/YYYY HH:mm") : null,
        value: (ticket?.Payment?.amount - ticket?.Payment?.platformFee) || null,
        paymentMethod: ticket ? PAYMENT.paymentMethod[ticket?.Payment?.paymentMethod]?.text : null,
        paymentType: ticket ? PAYMENT.paymentType[ticket?.Payment?.paymentType]?.text : null,
        ownerName: ticket?.Owner?.name || null,
        ownerPhone: ticket?.Owner?.phone || null,
      })

      r.getCell("date").numFmt = "DD/MM/YYYY HH:mm";
      r.getCell("value").numFmt = "R$#,##0.00";
      r.getCell("status").alignment = { vertical: "middle", horizontal: "center" };
    }
  }


  // Transactions
  const sheetTransactions = workbook.addWorksheet("Ingressos", {
    properties: { tabColor: { argb: "242939" } },
  });

  const columnsTransactions = [
    { header: "#", key: "id", width: 12 },
    { header: "Data", key: "date", width: 22 },
    { header: "Assento", key: "table", width: 25 },
    { header: "Setor", key: "group", width: 25 },
    { header: "Lote", key: "block", width: 25 },
    { header: "Válidado", key: "validated", width: 20 },
    { header: "Valor Líquido", key: "value", width: 20 },
    opMode ? { header: "Taxa Plataforma", key: "platformFee", width: 20 } : null,
    // opMode ? { header: "Taxa Cliente", key: "clientFee", width: 20 } : null,
    { header: "Meio de pagamento", key: "paymentMethod", width: 25 },
    { header: "Forma de pagamento", key: "paymentType", width: 25 },
  ].filter((column) => !!column);

  sheetTransactions.columns = columnsTransactions.flat();
  applyStyle(sheetTransactions, {
    font: { name: "Courier New" }
  });

  sheetTransactions.getRow(1).font = { bold: true, name: "Courier New" };
  sheetTransactions.columns.forEach((column, index) => {
    if (index < 5) column.alignment = { vertical: "middle", horizontal: "left" };
    else column.alignment = { vertical: "middle", horizontal: "center" }
  });

  const tickets = Object.values(groups).flatMap((group) => group.tickets);

  tickets
    .sort((a, b) => a.Payment.createdAt - b.Payment.createdAt)
    .forEach((ticket) => {
      const r = sheetTransactions.addRow({
        id: ticket.id.substring(0, 8),
        date: moment(ticket.Payment.createdAt).format("DD/MM/YYYY HH:mm"),
        table: `${ticket.Table.Group.name} • ${ticket.Table.name}`,
        group: ticket.TicketBlock.TicketGroup.name,
        block: ticket.TicketBlock.name,
        validated: ticket._count.TicketEntry ? "Sim" : "Não",
        value: ticket.amount - ticket.platformFee,
        platformFee: ticket.platformFee || 0,
        // clientFee: ticket.clientFee || 0,
        paymentMethod: PAYMENT.paymentMethod[ticket.Payment.paymentMethod].text,
        paymentType: PAYMENT.paymentType[ticket.Payment.paymentType].text,
      });
      r.getCell(6).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
    });


  // Protect sheet
  // if (!opMode) {
  //   const ramdomPassword = Math.random().toString(36).slice(-8);
  //   console.log(ramdomPassword);
  //   sheetResume.protect(ramdomPassword, {
  //     sort: true,
  //     autoFilter: true,
  //     selectLockedCells: false,
  //   });
  // }

  downloadFile(workbook, `Assentos - ${party.name}`);
  return true;
};


function applyStyle(sheet, style) {
  const { font, alignment } = style;
  sheet.columns.forEach((column, index) => {
    if (font) column.font = font;
    if (alignment) column.alignment = alignment;
  });
} 