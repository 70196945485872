<template>
  <v-dialog v-model="dialog" width="500" :persistent="!saleLink.id">
    <v-card>
      <v-card-title class="text-h6">
        {{ saleLink.id ? "Editar link de venda" : "Adicionar link de venda" }}
      </v-card-title>
      <v-card-text class="pt-1">
        <v-form v-model="valid" ref="form">
          <v-text-field
            outlined
            v-model="saleLink.name"
            :rules="[(v) => !!v || 'Nome é obrigatório']"
            label="Nome"
          ></v-text-field>
          <v-select
            outlined
            v-model="saleLink.ticketGroups"
            :items="ticketGroups"
            :rules="[(v) => !!v.length || 'Ao menos um setor é obrigatório']"
            label="Setores"
            multiple
            chips
            small-chips
          ></v-select>
        </v-form>
        <v-alert v-if="error" type="error" text>
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-switch
          v-if="saleLink.id"
          v-model="saleLink.active"
          :label="saleLink.active ? 'Ativo' : 'Inativo'"
        />
        <v-spacer></v-spacer>
        <v-btn
          class="ml-1"
          color="secondary"
          text
          :disabled="loading"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!valid"
          @click="save"
          :loading="loading"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";
import TICKET from "@/services/admin/ticket";

const defaultSaleLink = () => ({
  name: "",
  active: true,
  ticketGroups: [],
});

export default {
  data: () => ({
    dialog: false,
    loading: false,
    valid: false,
    error: false,
    saleLink: defaultSaleLink(),
    ticketGroups: [],
  }),

  methods: {
    open(data = {}) {
      this.saleLink = Object.assign(defaultSaleLink(), data);
      if (this.saleLink.TicketGroup) {
        this.saleLink.ticketGroups = this.saleLink.TicketGroup.map(
          (tg) => tg.id
        );
        delete this.saleLink.TicketGroup;
      }
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.loading = false;
        this.error = false;
        this.saleLink = defaultSaleLink();
      });
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;

        let party = this.party;
        let response;
        if (this.saleLink.id) {
          response = await ORGANIZATION.party.saleLink.update(
            party.organizationId,
            party.id,
            this.saleLink
          );
        } else {
          response = await ORGANIZATION.party.saleLink.create(
            party.organizationId,
            party.id,
            this.saleLink
          );
        }
        this.$emit("success", response.saleLink);
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
    async getTicketGroups() {
      try {
        const response = await TICKET.getTickets(
          this.party.organizationId,
          this.party.id
        );
        this.ticketGroups = response.ticketGroups.map((tg) => ({
          text: tg.name,
          value: tg.id,
        }));
      } catch (e) {
        this.error = e.message || "Não foi possível carregar os setores";
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$parent.$on("sale-link-modal", this.open);
    this.getTicketGroups();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
